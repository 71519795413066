/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRoutes from './routes/Routes';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import LogRocket from 'logrocket'
import { redactSensitiveData } from './utils/utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logError } from './utils/errorLogger';

// Define the Banner component
const Banner = () => (
    <div style={{ 
        backgroundColor: 'yellow', 
        padding: '10px', 
        textAlign: 'center', 
        position: 'fixed', 
        bottom: 0, 
        width: '100%', 
        zIndex: 1000 
    }}>
        You are on the demo site. Visit the production site at <a href="https://wavesign.co">wavesign.co</a>
    </div>
);

// Global error handler
window.addEventListener('error', (event) => {
    logError(event.error, { url: window.location.href });
    event.preventDefault();
});

try {
    LogRocket.init('t6orne/wavesign', {
        network: {
            isEnabled: true,
            requestSanitizer: (request) => {
                if (request.headers) {
                    delete request.headers['Authorization'];
                    delete request.headers['ApiKey'];
                    delete request.headers['Cookie'];
                    delete request.headers['X-Firebase-Client'];
                }
                if (request.body) {
                    request.body = redactSensitiveData(request.body);
                }
                return request;
            },
            responseSanitizer: (response) => {
                if (response.headers) {
                    delete response.headers['Set-Cookie'];
                }
                if (response.body) {
                    response.body = redactSensitiveData(response.body);
                }
                return response;
            },
        },
        browser: {
            urlSanitizer: (url) => {
                return url.replace(/token=[^&]+/g, 'token=[REDACTED]');
            }
        },
        dom: {
            isEnabled: true,
            inputSanitizer: true,
            textSanitizer: false,
        },
    });

    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );

    const showBanner = window.location.hostname.includes('demo.wavesign.co') || window.location.hostname.includes('localhost');
    console.log('Show Banner:', showBanner); // Debug log

    root.render(
        <React.StrictMode>
            <AuthProvider>
                <React.Suspense fallback={<div>Loading...</div>}>
                    {(!window.location.hostname.includes('app.wavesign.co') && !window.location.hostname.includes('wavesign.co')) && <Banner />}
                    <BrowserRouter>
                        <AppRoutes />
                        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                    </BrowserRouter>
                </React.Suspense>
            </AuthProvider>
        </React.StrictMode>
    );
} catch (error) {
    console.error('Error during application initialization:', error);
    // Optionally, you can display a user-friendly error message or fallback UI here
}
